import { browser } from '$app/environment';
import { QueryClient } from '@tanstack/svelte-query';

export function createQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				enabled: browser,
				staleTime: Infinity,
			},
		},
	});
}
